import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="contact section-padding">
          <div className="container">
            <div className="lg-bold-head text-center gray mb-80">
              <h2 className="sub-font">
                C
                <span className="inline">
                  O
                  <span className="icon">
                    <img src="assets/imgs/vector-img/Vector.svg" alt="" />
                  </span>
                </span>
                NTACT US
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="info md-mb80">
                  <div className="sec-head mb-40">
                    <h6 className="mb-15 fw-400">// Contact Us</h6>
                    <h3 className="fw-700">
                      Get in touch with us for any query.
                    </h3>
                    <p className="fz-22 fw-300 mt-15">
                      Apex Learning Institute is located in Chandigarh, serving
                      top class education to students. Reach us through email,
                      call or visit us.
                    </p>
                  </div>
                  <div className="list-arrow">
                    <ul className="rest">
                      <li className="mb-15">
                        <i className="pe-7s-phone icon"></i>{" "}
                        <a href="tel:+917973479377">+91 79734-79377</a>
                        ,&nbsp;&nbsp;&nbsp;
                        <a href="tel:+917973856917">+91 79738-56917</a>
                      </li>
                      <li className="mb-15">
                        <i className="pe-7s-mail icon"></i>{" "}
                        <a href="mailto:apexlearninginstitute5@gmail.com">
                          apexlearninginstitute5@gmail.com
                        </a>
                      </li>
                      <li>
                        <i className="pe-7s-map-marker icon"></i>SCO 24, 2nd
                        Floor, Sector 41D, Chandigarh, 160036
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="sec-head mb-40">
                  <h6 className="mb-15 fw-400">// Get In Touch</h6>
                  <h3 className="fw-700">
                    Send us a message to get in touch with us.
                  </h3>
                </div>
                <form id="contact-form" method="post" action="">
                  <div className="messages"></div>

                  <div className="controls row">
                    <div className="col-lg-6">
                      <div className="form-group mb-30">
                        <label for="">Your Name</label>
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          placeholder="Enter Your Name"
                          required="required"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group mb-30">
                        <label for="">Your Mail</label>
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          placeholder="infoflex@info.com"
                          required="required"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label for="">Mssage</label>
                        <textarea
                          id="form_message"
                          name="message"
                          placeholder="Write your message"
                          rows="4"
                          required="required"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="text-info mt-15">
                    <p className="fz-14">
                      * Call us Mon-Sat, 10AM-7PM or fill out the form below to receive a
                      free.
                    </p>
                  </div>
                  <div className="mt-50">
                    <button type="submit" className="d-flex align-items-center">
                      <span className="text">Post Message</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="37"
                        height="36"
                        viewBox="0 0 37 36"
                        fill="none"
                      >
                        <path
                          d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                          stroke="#fff"
                          strokeWidth="2"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
